import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      style={{backgroundColor:"#FB584D", color:"white", minHeight:"60px", paddingLeft:"10px",paddingRight:"10px"}}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  }
}));

export default CustomChip;
