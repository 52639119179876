import React, { useState, useEffect } from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './components/login';
import ForgotPassword from './components/forgotPassword';
import ChangePassword from './components/changePassword';
import LandingLogo from './assets/main-logo.svg';
import LoggedInTabs from './components/loggedInTabs';
import LoggedInTabsCanvasJS from './components/loggedInTabsCanvasJS';
import LoggedInTabsRecharts from './components/loggedInTabsRecharts';

import {
  makeStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  logoLoading: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: '#fcfcff;'
  },
  img: {
    position: 'absolute',
    color: 'white',
  }
}));

function App() {
  const [splash, setLoading] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => setLoading(false), 8000)
  }, [])

  return (
    <>

      {splash === false ? (
        <Router>
          <Switch>
            <Route exact path="/" >
              <div className="components">
                <Login />
              </div>
            </Route>
            <Route path="/web/forgotPassword" >
              <div className="components">
                <ForgotPassword />
              </div>
            </Route>
            <Route path="/web/changePassword" >
              <div className="components">
                <ChangePassword />
              </div>
            </Route>
            <Route path="/web/loggedInTabs" >
              <div className="components">
                <LoggedInTabs />
              </div>
            </Route>
            <Route path="/web/loggedInTabsCanvasJS" >
              <div className="components">
                <LoggedInTabsCanvasJS />
              </div>
            </Route>
            <Route path="/web/loggedInTabsRecharts" >
              <div className="components">
                <LoggedInTabsRecharts />
              </div>
            </Route>
          </Switch>
        </Router>
      ) : (
        <div id="logoLoading" className={classes.logoLoading}>
          <img src={LandingLogo} className={classes.img} alt="Loading..." />
        </div>
      )}

    </>
  );
}

export default App;