import React, { useEffect, useState } from "react";
import "./css/tropsensor.css";
import CustomChip from "./CustomChip";
import ClickToCopy from "../assets/clickToCopy.svg";
import CopiedToClipboard from "../assets/copiedToClipboard.svg";
import Stack from '@mui/material/Stack';
import { ToggleButtonGroup } from '@mui/material';
import { ToggleButton } from '@mui/material';
import { styled, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ReactApexChart from 'react-apexcharts'
import axios from "axios";
import moment from "moment";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 30,
  padding: 0,
  marginLeft: "10px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#FB584D",
        opacity: 1,
        border: 0,
        "&:before": {
          content: '"on"',
          left: 12,
          color: "white",
          paddingLeft: "1px",
        },
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },

    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 26,
    height: 26,
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles((theme) => ({
  muiDateTimePicker: {
    "& .MuiOutlinedInput-input": {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    },
    "& .MuiOutlinedInput-root":{
      height:"35px",
    }
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: "4px",
  },
  timeFontColor: {
    fontSize: "12px",
    color: "#7B8D97",
  },
  select: {
    width: "100%",
    height: "35px",
    borderRadius: "90px",
    border: "1px solid #DADADA",
    color: "#455065",
  },
  textfield:{
    height: "3px",
  }
}));

export default function TropSensor() {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState();
  const [device, getDevice] = React.useState([]);
  const [startDate, setDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [deviceSelected, setDeviceSelected] = React.useState(true);
  const [timeValue, setTime] = React.useState();
  const [deviceData, getdeviceData] = React.useState([]);
  const [switchState, setSwitchState] = React.useState(false);
  const [click, setClick] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [selectHour, setHour] = React.useState('1hour');
  useEffect(() => {
    troponinData(inputValue);
  }, [selectHour]);
  const [selectTroptime, selectedTroptime] = React.useState(0);
  const [selecttropnin, selectedtropnin] = React.useState(0);

  const [copyTitle, setCopyTitle] = useState("Click to copy");
  const copyToClipboard = (text) => navigator.clipboard.writeText(text);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const handleHour = (event, newHour) => {
    if (newHour !== null) {
      setHour(newHour)
    }
  };

  function onClickCopyToClipBoard(timeCopyValue){
    setClick(!click)
    if(!click){
      setCopyTitle("Copied to Clipboard");
    }else{
      setCopyTitle("Click to copy");
    }
    copyToClipboard(timeCopyValue)
  }

  function convertUTCToLocal(utcDt, utcDtFormat) {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt).format('YYYY/MM/DD hh:mm:ss');
  }  

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    // This is to get the live data every 2 minutes
    if (inputValue) {
      const interval = setInterval(() => {
        console.log(
          "This will run every 30 seconds to get the live data for this device" +
            inputValue
        );
        troponinData(inputValue);
      }, 30000);
      // This return is to unmount
      return () => clearInterval(interval);
    }
  }, [inputValue, selectHour]);

  const [series,objectSeries] = useState([{
    name: 'Troponin value',
    color: '#FB584D',
    data: []
  }])
  const [options,objectOptions] =useState({
    chart: {
      height: 350,
      type: 'scatter',
      zoom: {
        type: 'xy'
      },
      toolbar: {
        show:  true,
        tools: {
          download: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true
        }
      },
      events: {
          markerClick: function(event, chartContext, { seriesIndex, dataPointIndex, config}) {
            var troptimeData = chartContext.w.globals.initialSeries[seriesIndex].data[dataPointIndex]
            selectedTroptime(troptimeData[0].toString());
            selectedtropnin(troptimeData[2]);
            
          }
      },
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: {
        format: "yyyy/MM/dd HH:mm:ss"
      }
    },
    grid: {
      show: true,
      borderColor: '#F2BDBAAA',
      strokeDashArray: 0,
      position: 'back',
        
      yaxis: {
        min: 0,
        forceNiceScale: true,       
          lines: {
              show: true
          }
      },  
      xaxis: {
        type: "datetime",
        lines: {
          show: true
        }
      },
      row: {
          colors: undefined,
      },  
      column: {
          colors: undefined,
      },  
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 400,
        },
      },
      categories: []
    },
  })

  function handleSelect(e) {
    handleChange(e,0)
    selectedTroptime(0);
    selectedtropnin(0);
    setInputValue(e.currentTarget.value);
    setDeviceSelected(true);
    troponinData(e.currentTarget.value);
  }

  function batteryLife(batteryPercentage) {
    let percentage = document.querySelector(".percentage");
    let percent = document.querySelector(".percent");
    percentage.style.width = batteryPercentage + "%";
    percent.innerHTML = batteryPercentage + "%";
  }

  function getTropDevice() {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios
      .get("/api/v1/device/?is_trop=true&is_active=true&limit=1000", config)
      .then(
        (res) => {
          getDevice(res.data.results);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  function getCurrentUser() {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios.get("/api/v1/profile/", config).then(
      (res) => {
        if (res.data.clinic) {
          getTropDevice();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function trimtrop(tropvalue){
    tropvalue = "'"+tropvalue+"'"
    let tropValueCropped = tropvalue;
    let hasMinus = /\-/;
    if(hasMinus.test(tropValueCropped)){
        if(tropValueCropped.indexOf(".") == 6){
          
          tropValueCropped = tropValueCropped.slice(1,6);
        }else{
          tropValueCropped = tropValueCropped.slice(1,8);
        }
    }else if(tropvalue.length >= 5){
      var hasDot = /\./;  
      if(hasDot.test(tropValueCropped)){
        if(tropValueCropped.indexOf(".") == 5){
          
          tropValueCropped = tropValueCropped.slice(1,5);
        }else{
          tropValueCropped = tropValueCropped.slice(1,7);
        }
      }else{
        tropValueCropped = tropValueCropped.slice(1,6);
      }
    }
    tropValueCropped = tropValueCropped.replace(/['"]+/g, '');
    return tropValueCropped;
  }

  function getHours() {
    return selectHour == '1hour' ? 1 : 24;
  }

  function troponinData(val) {
    
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
      params: {
        hours: getHours()
      }
    };
    axios.get("/api/v1/trop/" + val + "/", config).then(
      (res) => {
        console.log(res)
        
        if(res.data.battery_life) {
          batteryLife(res.data.battery_life)
        }else{ batteryLife(0)}
        if(res.data.data){
          tropninGraph(res.data.data)
        }else{
          tropninGraph(res.data)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function filteredTroponinData(ttime) {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios.get("/api/v1/trop/" + inputValue +"/?trop_filter_date=" +startDate+ "&trop_filter_time="+ ttime, config).then(
      (res) => {
        console.log(res)
        if(res.data.battery_life) {
          batteryLife(res.data.battery_life)
        }else{ batteryLife(0)}
        if(res.data.data){
          tropninGraph(res.data.data)
        }else{
          tropninGraph(res.data)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  function tropninGraph(tropData){
    const troponin = [];
    const localtime = [];
    const getMaxtropValue = [];

    tropData.map(item =>{
      let tropValueCropped = trimtrop(item.troponent_value.trim());
      let tropWithoutcropped = tropValueCropped
      if(tropValueCropped > 1000){
        tropValueCropped = '1000';
      }
      let dateformatted = item.entry_timestamp.replace(/-/g, "/")
      getMaxtropValue.push(tropValueCropped);
      troponin.push([dateformatted,tropValueCropped, tropWithoutcropped]);
      localtime.push([dateformatted])
    })
    let maxxxx = Math.max(...getMaxtropValue);
    objectSeries([{
      data: troponin,
    }])
    objectOptions({
      yaxis :{
        labels: {
          formatter: (value) => {
            let valueformated = trimtrop(value);
            return valueformated+ " ng/L";
          },
          style: {
            fontSize: '12px',
            fontFamily: '"Poppins", sans-serif',
            fontWeight: 400,
          },
        },
        min: 0,
        max: maxxxx == 0 ? (1000):(maxxxx)
      },
      noData: {
        text: "There's no data",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0
      },
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          var datetime = moment.parseZone(data[0]).format("YYYY/MM/DD HH:mm:ss");
          var troponin = data[2]
          var localTime = convertUTCToLocal(datetime);  
          setClick(false);
          setCopyTitle("Click to Copy");

          return '<ul style="margin-top:15px;margin-right:10px">' +
          '<li><b>UTC</b>: ' + datetime+ '</li>' + 
          '<li><b>Troponin</b>: ' + troponin+ '</li>' + 
          '<li><b>Local time</b>: ' + localTime+ '</li>' + 
          '</ul>';
        },
        
      },
    })
  }
  return (
    <>
      <div className="mainTropsensor">
        <div>
          <label
            className="mb-2"
            style={{ marginLeft: "15px", fontSize: "15px" }}
          >
            Device
          </label>
          <div className="batteryContent">
            <div
              className="percent"
              style={{ marginRight: "3px", fontSize: "12px" }}
            >
              100%
            </div>
            <div className="batteryShape">
              <div className="level">
                <div className="percentage"></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <select
            className={classes.select}
            onChange={handleSelect}
            value={inputValue}
            defaultValue=""
          >
            <option value="" className="text-center" disabled>
              Select Device
            </option>
            {device.map((device) => {
              return (
                <option
                  className="text-center"
                  value={device.udid}
                  key={device.id}
                >
                  {device.udid}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mt-4 text-center">
          <ToggleButtonGroup
          color="primary"
          value={selectHour}
          exclusive
          aria-label="Hours Selection"
          onChange={handleHour}
          >
          <ToggleButton value="1hour">1 Hour</ToggleButton>
          <ToggleButton value="24hours">24 Hours</ToggleButton>
          </ToggleButtonGroup>
        </div>
        {inputValue ? (
          <div >
            <div id="chart" className="mt-3" >
            <ReactApexChart options={options} series={series} type="scatter" height={500} />
            </div>
            {selectTroptime && selecttropnin ?(

              <Stack direction="row" spacing={1} style={{justifyContent: "center"}}>
                {!click ? 
                <CustomChip
                  size={1.2}
                  label={
                    <div >
                      <div id="trop-text" style={{fontSize:"10px"}}>
                      {`UTC: ${selectTroptime}`}
                      </div>
                      <div id="trop-text" style={{fontSize:"10px"}}>
                      {`Troponin: ${selecttropnin}`}
                      </div>
                      <h6 id="copy-text" style={{marginBottom:'0px'}}>
                      {copyTitle}
                      </h6>
                    </div>
                 }
                 onClick={() => onClickCopyToClipBoard(`UTC: ${selectTroptime}, Troponin: ${selecttropnin}`)}
                 icon={<img
                  src={ClickToCopy}
                  alt="ClickToCopy"
                />}
                ></CustomChip>
                :
                <CustomChip
                  size={1.2}
                  label={
                    <div >
                      <h6 id="copy-text" style={{marginBottom:'0px'}}>
                      {copyTitle}
                      </h6>
                    </div>
                 }
                 onClick={() => onClickCopyToClipBoard(`UTC: ${selectTroptime}`)}
                 icon={<img
                  src={CopiedToClipboard}
                  alt="CopiedToClipboard"
                />}
                ></CustomChip>}
              </Stack>
              
            ):(
              <div></div>
            )}
            
          </div>
        ) : (
          <div className={classes.flex} style={{marginTop: "10px"}}>No Device Data Available</div>
        )}
      </div>
    </>
  );
}
