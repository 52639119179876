import React, { useState } from "react";
import { Card, Container, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";
import MainLogo from "../assets/main-logo.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "500px",
    marginTop: "auto",
    marginBottom: "auto",

    borderRadius: "3px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
    paddingLeft: "90px",
    paddingRight: "90px",
    "@media (max-width: 576px)": {
      width: "300px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  h3: {
    textAlign: "center",
    fontSize: "22px",
    color: "#455065",
    fontWeight: "500",
    marginTop: "10px",
  },
  inputField: {
    height: "56px",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    borderRadius: "3px",
  },
  textColor: {
    color: "#455065",
  },
  button: {
    background: "#FB584D",
    height: "46px",
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  a: {
    textDecoration: "none",
    color: "#FB584D",
    fontSize: "12px",
    "&:hover": {
      color: "#FB584D",
    },
  },
}));

async function newPassword(credentials) {
  return fetch("/api/v1/auth/password/change/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("key"),
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function ChangePassword() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const logIn = localStorage.getItem("key");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // perform all neccassary validations
    if (password !== confirmPassword) {
      setError("Passwords don't match");
    } else {
      const response = await newPassword({
        new_password1: password,
        new_password2: confirmPassword,
      });
      console.log(response);
      if ("detail" in response) {
        setError("");
        setMessage(response.detail);
        localStorage.removeItem("key");
      } else {
        if ("new_password2" in response) {
          console.log(response.new_password2);
          setError(response.new_password2);
        } else {
          setError(response);
        }
      }
    }
  };
  return (
    <>
      {logIn ? (
        <Container style={{ height: "100vh" }}>
          <div className="d-flex justify-content-center h-100">
            <Card className={classes.card + " card-forgot pb-4"}>
              <img
                src={MainLogo}
                style={{ width: "248px", height: "140px" }}
                alt="mainlogo"
                className="mx-auto"
              />
              <h3 className={classes.h3}>Change Password</h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="form.Password" className="mt-2">
                  {error && <Alert variant="danger">{error}</Alert>}
                  {message && <Alert variant="success">{message}</Alert>}
                  <Form.Label className={classes.textColor}>
                    New password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Create a new password"
                    className={classes.inputField}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="form.Password" className="mt-2 mb-4">
                  <Form.Label className={classes.textColor}>
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm your password"
                    className={classes.inputField}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                <div className="d-grid mt-2 mb-4 mt-4">
                  <button className={"btn " + classes.button} type="submit">
                    Change
                  </button>
                </div>
              </Form>
            </Card>
          </div>
        </Container>
      ) : (
        <Container
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <div className="d-block">Not Logged in</div>
            <div>
              <Link to="/" className={classes.a}>
                Click here to Login
              </Link>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
