import React, { useEffect } from "react";
import "./css/ecg.css";
import { styled, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from "@material-ui/core/Grid";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';

import Dygraph from "dygraphs";

import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

import axios from "axios";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 30,
  padding: 0,
  marginLeft: "10px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#FB584D",
        opacity: 1,
        border: 0,
        "&:before": {
          content: '"on"',
          left: 12,
          color: "white",
          paddingLeft: "1px",
        },
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },

    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 26,
    height: 26,
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      color: '#455065', // or black
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    }
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timeFontColor: {
    fontSize: "12px",
    color: "#7B8D97",
  },
  select: {
    width: "100%",
    height: "35px",
    borderRadius: "90px",
    border: "1px solid #DADADA",
    color: "#455065",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100,
    "& .MuiOutlinedInput-input": {
      color: '#455065', // or black
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    }
  },
}));

export default function ECG() {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState();
  const [device, getDevice] = React.useState([]);
  const [switchState, setSwitchState] = React.useState(true);
  const [date, getDate] = React.useState([]);
  const [dateValue, setDateValue] = React.useState();
  const [timeStampData, getTimedata] = React.useState([]);
  const [inputTimeValue, setTimeValue] = React.useState();
  const [selectDateFirst, setDateFirst] = React.useState(true);
  const [deviceUdid, currentDeviceudid] = React.useState();

  function handleSwitchChange(e) {
    setSwitchState(e.target.checked);
    if(e.target.checked){
      ecgFiltered(deviceUdid)
      setDateFirst(true)}
    else{ecgData(deviceUdid);}
  }

  useEffect(() => {
    getCurrentUser();
    drawdygraph()
  }, []);

  useEffect(() => {
    if (inputValue && switchState) {
      const interval = setInterval(() => {
        console.log(
          "This will run every 1 minute! to get the live data for this device" +
            inputValue
        );
        ecgData(inputValue);
      }, 60000);
      // This return is to unmount
      return () => clearInterval(interval);
    }
  }, [inputValue,switchState]);

  function handleSelect(e) {
    getDate([]);
    getTimedata([]);
    setDateValue();
    setTimeValue();
    setDateFirst(true);
    setInputValue(e.currentTarget.value);
    currentDeviceudid(e.currentTarget.value)
    if(switchState){
      ecgFiltered(e.currentTarget.value)}
    else{ ecgData(e.currentTarget.value);}
   
  }

  function handledateSelect(e){
    setTimeValue();
    setDateValue(e.target.value);
    setDateFirst(false);
    const selectedTime = e.target.value
    const datTimeValue = date[selectedTime];
    getTimedata(datTimeValue);
  }

  function handletimeSelect(e) {
    setTimeValue(e.target.value);
    ecgFilteredDateTime(e.target.value);
  }

  function drawdygraph() {
    window.g = [];

    var labelarr = [
      "V6",
      "I",
      "II",
      "V2",
      "V3",
      "V4",
      "V5",
      "V1",
      "III",
      "AVL",
      "AVF",
      "AVR",
    ];
    for (var ch = 1; ch <= 12; ch++) {
      var data = [[0, 0]];
      var gr = new Dygraph(
        document.getElementById("graph" + ch),
        data,
        {
          axisLineColor: "rgb(123,0,0)",
          title: labelarr[ch - 1],
          titleHeight: 1,
          height:300,
          width:400,
          axisLabelWidth: 40,
          axes: {
            x: {
              drawAxis: false,
            },
            y: {
              drawGrid: false,
            },
          },
          showLabelsOnHighlight: false,
          color: "#000",
          labels: ["X", labelarr[ch - 1]],
          drawGrid: false,
          underlayCallback: function (canvas, area, g) {
            //draw y minor axis
            for (var i = 0; i <= 2400; i += 8) {
              var tick = i;
              const x = g.toDomXCoord(tick);
              //canvas.fillRect(x, area.y, x, area.h);
              canvas.strokeStyle = "#F2BDBAAA";

              canvas.beginPath();
              canvas.moveTo(x, area.y);
              canvas.lineTo(x, area.h);
              canvas.stroke();
            }
            //draw x minor axis
            for (var i = area.y; i <= area.h; i += 10) {
              canvas.strokeStyle = "#F2BDBAAA";
              var x = g.toDomXCoord(0);
              var x2 = g.toDomXCoord(2400);
              canvas.beginPath();
              canvas.moveTo(x, i);
              canvas.lineTo(x2, i);
              canvas.stroke();
            }
            //draw y major axis
            for (var i = 0; i <= 2400; i += 40) {
              var tick = i;
              const x = g.toDomXCoord(tick);
              //canvas.fillRect(x, area.y, x, area.h);
              canvas.strokeStyle = "#F58F8F";

              canvas.beginPath();
              canvas.moveTo(x, area.y);
              canvas.lineTo(x, area.h);
              canvas.stroke();
            }
            //draw x major axis
            for (var i = area.y; i <= area.h; i += 50) {
              canvas.strokeStyle = "#F58F8F";
              var x = g.toDomXCoord(0);
              var x2 = g.toDomXCoord(2400);
              canvas.beginPath();
              canvas.moveTo(x, i);
              canvas.lineTo(x2, i);
              canvas.stroke();
            }
          }, //underlayCallback
        } //dict
      ); //dygraphs

      window.g.push(gr);
    }
  }

  async function processData(dat) {
    var res = dat;
    res.shift();
    var data = [];
    for (var ch = 1; ch <= 8; ch++) {
      data = [];
      for (var i = 0; i < res.length - 1; i++) {
        data.push([parseInt(res[i][0]), parseFloat(res[i][ch])]);
      }
      window.g[ch - 1].updateOptions({ file: data });
      console.log("channel:", ch - 1);
    }

    data = [];
    for (var i = 0; i < res.length - 1; i++) {
      data.push([
        parseInt(res[i][0]),
        parseFloat(res[i][3]) - parseFloat(res[i][2]),
      ]);
    }
    window.g[8].updateOptions({ file: data });
    var lead3 = data.slice();

    //     avl      = (channel1-lead3)/2

    data = [];
    for (var i = 0; i < res.length - 1; i++) {
      data.push([
        parseInt(res[i][0]),
        (parseFloat(res[i][1]) - lead3[i][1]) / 2.0,
      ]);
    }
    window.g[9].updateOptions({ file: data });

    //     avf      = (channel2+lead3)/2
    data = [];
    for (var i = 0; i < res.length - 1; i++) {
      data.push([
        parseInt(res[i][0]),
        (parseFloat(res[i][2]) + lead3[i][1]) / 2.0,
      ]);
    }
    window.g[10].updateOptions({ file: data });

    //     avr      = -1.0 *(channel1+channel2)/2
    data = [];
    for (var i = 0; i < res.length - 1; i++) {
      data.push([
        parseInt(res[i][0]),
        (-1.0 * (parseFloat(res[i][1]) + parseFloat(res[i][2]))) / 2.0,
      ]);
    }
    window.g[11].updateOptions({ file: data });
  }

  function getEcgDevice() {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios
      .get("/api/v1/device/?is_trop=false&is_active=true&limit=1000", config)
      .then(
        (res) => {
          if (res) {
            getDevice(res.data.results);
            if(switchState){ecgFiltered(res.data.results[0].udid);
            }else{ ecgData(res.data.results[0].udid);}
            currentDeviceudid(res.data.results[0].udid)
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  function ecgData(val) {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios.get("/api/v1/ecg/" + val + "/datetimes/", config).then(
      (res) => {
        getDate(res.data.data)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function ecgFilteredDateTime(val) {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios.get("/api/v1/ecg/" + deviceUdid + "/?filter_status=1&ecg_filter_date=" +dateValue+ "&ecg_filter_time="+ val, config).then(
      (res) => {
        processData(res.data.data.channel_data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function ecgFiltered(val) {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios.get("/api/v1/ecg/" + val + "/?filter_status=1", config).then(
      (res) => {
        processData(res.data.data.channel_data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function getCurrentUser() {
    const config = {
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
      },
    };
    axios.get("/api/v1/profile/", config).then(
      (res) => {
        if (res.data.clinic) {
          getEcgDevice();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  return (
    <>
      <div className="mainECG mb-5">
        <div>
          <label
            className="mb-2"
            style={{ marginLeft: "15px", fontSize: "15px" }}
          >
            Device
          </label>
        </div>
        <div>
          <select
            className={classes.select}
            onChange={handleSelect}
            value={inputValue}
          >
            {device.map((device) => {
              return (
                <option
                  className="text-center"
                  value={device.udid}
                  key={device.id}
                >
                  {device.udid}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mt-4">
          
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={3}>
            <Grid item style={{marginLeft:"5px", fontSize: "15px" }} xs={4}>
              Date
            </Grid>
            <Grid item style={{fontSize: "15px"}} xs={4}>
              Timestamp
            </Grid>
            <Grid item xs={3}>
            </Grid>
          </Stack>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={3} mt={1}>
            <Grid item xs={4}>
            <TextField
              select
              fullWidth
              size="small"
              label={dateValue ? "" :"Select"}
              className={classes.textField}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              style={{marginTop:0}}
              onChange={handledateSelect}
              value={dateValue ?? ""}
              InputLabelProps={{shrink: false}}
              margin="normal"
              variant="outlined"
              disabled = {switchState}
            >
              {Object.keys(date).map(key => (
                <MenuItem
                    className="text-center"
                    value={key}
                    key={key}
                  >
                    {key}
                  </MenuItem>
                ))}
            </TextField>
            </Grid>
            <Grid item xs={4}>
            <TextField
              select
              fullWidth
              size="small"
              label={inputTimeValue ? "" :"Select"}
              className={classes.textField}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              style={{marginTop:0}}
              onChange={handletimeSelect}
              value={inputTimeValue ?? ""}
              disabled = {selectDateFirst}
              InputLabelProps={{shrink: false}}
              margin="normal"
              variant="outlined"
            >
              {Object.values(timeStampData).map(key => (
                <MenuItem
                    className="text-center"
                    value={key}
                    key={key}
                  >
                    {key}
                  </MenuItem>
                ))}
            </TextField>
            </Grid>
            <FormControlLabel
            control={
              <IOSSwitch checked={switchState} onChange={handleSwitchChange} />
            }
            style={{ float: "right", marginRight: "0" }}
            label={
              <Typography style={{ fontSize: "15px" }}>Live Reload</Typography>
            }
            labelPlacement="start"
          />
          </Stack>
        </LocalizationProvider>
      </div>
      <div>
        <div className="row mx-auto mb-2">
          <div className="graph col-sm-3" id="graph2"></div>
          <div className="graph col-sm-3" id="graph12"></div>
          <div className="graph col-sm-3" id="graph8"></div>
          <div className="graph col-sm-3" id="graph6"></div>
        </div>

        <div className="row mx-auto mb-2">
          <div className="graph col-sm-3" id="graph3"></div>
          <div className="graph col-sm-3" id="graph10"></div>
          <div className="graph col-sm-3" id="graph4"></div>
          <div className="graph col-sm-3" id="graph7"></div>
        </div>

        <div className="row mx-auto">
          <div className="graph col-sm-3" id="graph9"></div>
          <div className="graph col-sm-3" id="graph11"></div>
          <div className="graph col-sm-3" id="graph5"></div>
          <div className="graph col-sm-3" id="graph1"></div>
        </div>
      </div>
    </>
  );
}
