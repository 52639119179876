import React, { useState } from "react";
import { Card, Container, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";
import MainLogo from "../assets/main-logo.svg";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: "auto",
    marginBottom: "auto",

    borderRadius: "3px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
    paddingLeft: "90px",
    paddingRight: "90px",
    "@media (max-width: 576px)": {
      width: "300px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  h3: {
    textAlign: "center",
    fontSize: "22px",
    color: "#455065",
    fontWeight: "500",
    marginTop: "10px",
  },
  inputField: {
    height: "56px",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    borderRadius: "3px",
  },
  textColor: {
    color: "#455065",
  },
  button: {
    background: "#FB584D",
    height: "46px",
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
}));

async function resetPassword(credentials) {
  return fetch("/api/v1/auth/password/reset/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await resetPassword({
      email,
    });
    console.log(response);

    if ("detail" in response) {
      setError("");
      setMessage("Check your inbox");
    } else {
      setMessage("");
      setError("Enter a valid email address");
    }
  };

  return (
    <Container style={{ height: "100vh" }}>
      <div className="d-flex justify-content-center h-100">
        <Card className={classes.card + " card-forgot pb-4"}>
          <img
            src={MainLogo}
            style={{ width: "248px", height: "140px" }}
            alt="mainlogo"
            className="mx-auto"
          />
          <h3 className={classes.h3}>Forgot password</h3>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="form.Email" className="mt-2">
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
              <Form.Label className={classes.textColor}>Email </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                className={classes.inputField}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <div className="d-grid mt-2 mb-4 mt-4">
              <button className={"btn " + classes.button} type="submit">
                Send email
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </Container>
  );
}
