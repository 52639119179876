import React, { useState } from "react";
import "./css/login.css";
import { Card, Container, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

import MainLogo from "../assets/main-logo.svg";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: "auto",
    marginBottom: "auto",

    borderRadius: "3px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
    paddingLeft: "90px",
    paddingRight: "90px",
    "@media (max-width: 576px)": {
      width: "300px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  h3: {
    textAlign: "center",
    fontSize: "22px",
    color: "#455065",
    fontWeight: "500",
    marginTop: "10px",
  },

  inputField: {
    height: "56px",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    borderRadius: "3px",
  },
  textColor: {
    color: "#455065",
  },
  a: {
    textDecoration: "none",
    color: "#FB584D",
    "&:hover": {
      color: "#FB584D",
    },
  },
  wellStyles: {
    maxWidth: 400,
    margin: "0 auto 10px",
  },
  button: {
    background: "#FB584D",
    height: "46px",
    color: "#FFFFFF",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
}));

async function loginUser(credentials) {
  return fetch("/api/v1/auth/login/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const [buttonText, setButtonText] = useState('SIGN IN');

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleClick() {
    setButtonText('Signing in...');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      email,
      password,
    });
    console.log(response);
    if ("key" in response) {
      setError("");
      localStorage.setItem("key", response["key"]);
      history.push("/web/loggedInTabs");
    } else {
      if ("email" in response) {
        console.log(response.email);
        setError(response.email);
      } else if ("non_field_errors" in response) {
        console.log(response.non_field_errors);
        setError(response.non_field_errors);
      }
    }
  };

  return (
    <Container style={{ height: "100vh" }}>
      <div className="d-flex justify-content-center h-100">
        <Card className={classes.card + " card-login pb-4"}>
          <img
            src={MainLogo}
            style={{ width: "248px", height: "140px" }}
            alt="mainlogo"
            className="mx-auto"
          />
          <h3 className={classes.h3}>Sign in to your account</h3>

          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="form.Email" className="mt-4">
              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Label className={classes.textColor}>Email </Form.Label>
              <Form.Control
                autoFocus
                type="email"
                placeholder="Enter your email address"
                className={classes.inputField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="form.Password" className="mt-4 mb-4">
              <Form.Label className={classes.textColor}>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                className={classes.inputField}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Text style={{ fontWeight: "500", color: "#7B8D97" }}>
              Forgot your password?{" "}
              <Link to="/web/forgotPassword" className={classes.a}>
                Reset Password
              </Link>
            </Form.Text>
            <div className="d-grid mt-4 mb-2">
              <button
                className={"btn " + classes.button}
                type="submit"
                disabled={!validateForm()}
                onClick={handleClick}
              >
                {buttonText}
              </button>
            </div>
          </Form>
        </Card>
      </div>
    </Container>
  );
}
