import React from "react";
import TropSensorCanvasJS from "./tropSensorCanvasJS";
//import ECG from "./ecg";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Toolbar } from "@material-ui/core";

import { Link, useHistory } from "react-router-dom";

import { Container } from "react-bootstrap";
import AppLogo from "../assets/small-logo.png";

import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ marginTop: "50px" }}
      >
        {children}
      </Grid>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  mainTab: {
    color: "#455065 !important",
    minHeight: "100vh",
  },
  tabs: {
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tabLabel: {
    "@media (min-width: 768px)": {
      fontSize: "16px",
      letterSpacing: "1.5px",
    },
    "@media (min-width: 992px)": {
      fontSize: "16px",
      letterSpacing: "1.5px",
    },
    "@media (min-width: 1200px)": {
      fontSize: "16px",
      letterSpacing: "1.5px",
    },
    fontSize: "calc(0.375rem + 1.5vw)",
    fontWeight: "600",
    color: "#455065",
  },
  button: {
    textTransform: "none",
    fontSize: "14px",
    color: "#455065",
    float: "right",
  },
  a: {
    textDecoration: "none",
    color: "#FB584D",
    fontSize: "12px",
    "&:hover": {
      color: "#FB584D",
    },
  },
  img: {
    "@media (max-width: 576px)": {
      width: "125px",
    },
  },
}));

export default function LoggedInTabs() {
  const classes = useStyles();
  const logIn = localStorage.getItem("key");
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const themes = createTheme({
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      color: "#455065",
    },
  });

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleLogout() {
    const logoutOptions = {
      url: "/api/v1/auth/logout/",
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("key"),
        "Content-Type": "application/json",
      },
    };

    axios(logoutOptions).then(
      (response) => {
        console.log(response);
        history.push("/");
        localStorage.removeItem("key");
      },
      (err) => {
        console.log(err);
      }
    );
  }

  return (
    <>
      {logIn ? (
        <ThemeProvider theme={themes}>
          <div className={classes.mainTab}>
            <AppBar position="static">
              <Toolbar
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <img src={AppLogo} alt="appLogo" className={classes.img} />
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  className={classes.tabs}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FB584D",
                      height: "4px",
                    },
                  }}
                >
                  {/*                   
                  <Tab
                    label={<span className={classes.tabLabel}>ECG</span>}
                    {...a11yProps(0)}
                  /> 
                   */}
                  <Tab
                    label={<span className={classes.tabLabel}>TROPSENSOR</span>}
                    {...a11yProps(1)}
                  />
                </Tabs>
                <div>
                  <div>
                    <Button
                      color="inherit"
                      className={classes.button}
                      onClick={handleLogout}
                    >
                      Sign Out
                    </Button>
                  </div>
                  <div style={{ textAlign: "end", paddingRight: "5px" }}>
                    <Link to="/web/changePassword" className={classes.a}>
                      Change Password
                    </Link>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            {/*
            <TabPanel value={value} index={0} style={{ margin: "20px" }}>
              <ECG />
            </TabPanel>
            */}
            <TabPanel value={value} index={0} style={{ margin: "20px" }}>
              <TropSensorCanvasJS />
            </TabPanel>
          </div>
        </ThemeProvider>
      ) : (
        <Container
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <div className="d-block">Not Logged in</div>
            <div>
              <Link to="/" className={classes.a}>
                Click here to Login
              </Link>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
